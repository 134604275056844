import React from "react"
import {Award,  Heart, Flag, Home, Lock, Users} from "react-feather"
import {trans} from "../../core/utility/Utils"
export const Navigation = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: <Home size={20}/>,
        navLink: '/dashboard',
        action: 'call',
        resource: 'NoPermissionCode'
    },
    {
        id: 'Activitieslist',
        title: 'Activities List',
        icon: <Heart size={12} />,
        navLink: '/Activitieslist',
        action: 'call',
        resource: 'NoPermissionCode'
    },
    {
        id: 'adminsList',
        title: 'Admins List',
        icon: <Lock size={12} />,
        navLink: '/admins/list',
        action: 'call',
        resource: 'NoPermissionCode'
    },
    {
        id: 'Userslist',
        title: 'Users List',
        icon: <Users size={12} />,
        navLink: '/Userslist',
        action: 'call',
        resource: 'NoPermissionCode'
    },
    {
        id: 'Reportslist',
        title: 'Reports List',
        icon: <Flag size={12} />,
        navLink: '/Reportslist',
        action: 'call',
        resource: 'NoPermissionCode'
    },
    {
        id: 'Subscriberslist',
        title: 'Subscribers List',
        icon: <Award size={12} />,
        navLink: '/Subscriberslist',
        action: 'call',
        resource: 'NoPermissionCode'
    }
]
