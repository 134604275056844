const PluggedInModules = {}

try {
    PluggedInModules['admin'] = {path: require('@devModules/admin'), enabled: true, enableRoutes: true, enableNavigations: true} // Admin Module ()
} catch (e) {
    // console.error('no Admin Module', e)
}

try {
    PluggedInModules['rolespermissions'] = {path: require('@devModules/rolespermissions'), enabled: true, enableRoutes: true, enableNavigations: true} // Admin Module ()
} catch (e) {
    // console.error('no Admin Module', e)
}

export default PluggedInModules
