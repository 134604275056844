import {lazy} from "react"

export const Routes = [
    {
        path: "/login",
        component: lazy(() => import("../views/auth/Login")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: "/dashboard",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            general: true
        }
    },
    {
        path: "/user/:userid",
        component: lazy(() => import("../views/userprofile")),
        meta: {
            general: true
        }
    },
    {
        path: "/admins/list",
        component: lazy(() => import("../views/admin-list")),
        meta: {
            general: true
        }
    },
    {
        path: "/Activitieslist",
        component: lazy(() => import("../views/Activities-list")),
        meta: {
            general: true
        }
    },
    {
        path: "/Userslist",
        component: lazy(() => import("../views/Users-list")),
        meta: {
            general: true
        }
    },
    {
        path: "/Reportslist",
        component: lazy(() => import("../views/Reports-list")),
        meta: {
            general: true
        }
    },
    {
        path: "/Subscriberslist",
        component: lazy(() => import("../views/Subscribers-list")),
        meta: {
            general: true
        }
    }
]
